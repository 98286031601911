import { useBookDetails } from '@/store/book-details';

export default defineNuxtRouteMiddleware(async (to) => {
  const bookDetailsStore = useBookDetails();
  try {
    await bookDetailsStore.getBookDetails(to.params.slug as string);
    if (bookDetailsStore.isUserHasAccessToBook) {
      return;
    } else {
      return navigateTo('/');
    }
  } catch (_) {
    return navigateTo('/');
  }
});
